<template>
  <div>
    <div class="d-flex justify-content-center" v-if="productDetail === null">
      <b-spinner label="Loading..." variant="primary" class="mr-1"></b-spinner>
      <strong>Loading...</strong>
    </div>
    <b-alert
      variant="danger"
      :show="productDetail === undefined"
    >
      <h4 class="alert-heading">
        Error fetching product or idea
      </h4>
      <div class="alert-body">
        Product or idea not found with this id.
      </div>
    </b-alert>
    <template v-if="productDetail">
      <div class="cws-container cws-sidebar-right blog-wrapper">

      <!-- content -->
      <div class="blog-detail-wrapper">
        <b-row>
          <!-- blogs -->
          <b-col cols="12">
            <b-card>
              <b-card :img-src="productDetail.image" img-alt="Card image" img-top style="max-width:400px; margin-left: auto; margin-right: auto; margin-bottom: 0; display: block; "></b-card>
              <h4>{{productDetail.title}}</h4>
              <b-media no-body>
                <b-media-body>
                  <small class="mr-50">{{ productDetail.name }}</small>
                  <small class="text-muted mr-50">by</small>
                  <small>
                    <b-link :to="{ name: 'apps-users-view', params: { id: productDetail.username }}" class="text-body">{{ productDetail.username }}</b-link>
                  </small>
                  <span class="text-muted ml-75 mr-50">|</span>
                  <small class="text-muted">{{ productDetail.date | dateFormat('MMMM DD, YYYY') }}</small>
                </b-media-body>
              </b-media>

              <div class="my-1 py-25">
                <b-badge class="mr-1" variant="primary" v-for="industry in productDetail.industries"
                  :key="industry">
                  <span>{{ industry }}</span>
                </b-badge>
              </div>
              <hr class="my-2">
              <!-- eslint-disable vue/no-v-html -->
              <div class="d-flex flex-column"
                v-html="productDetail.description"
              />
              <div class="mb-2">
              Specialization / Area of Focus: <span 
                class="badge badge-light-primary badge-skills" 
                v-for="tags in productDetail.tags"
                :key="tags"
                :value="tags"> {{ tags }}
              </span>
            </div>
              <!-- eslint-enable -->
              <hr class="my-2">

              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center mr-1">
                    <b-link class="mr-50">
                      <feather-icon
                        icon="MessageSquareIcon"
                        size="21"
                        class="text-body"
                      />
                    </b-link>
                    <b-link>
                      <div class="text-body">
                        {{ kFormatter(productDetail.comments) }} Comments
                      </div>
                    </b-link>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
          <!--/ blogs -->

          <!-- blog comment -->
          <b-col v-if="comments && comments.length > 0"
            id="blogComment"
            cols="12"
            class="mt-2"
          >
            <h6 class="section-label">
              Comments
            </h6>
            <b-card
              v-for="(comment,index) in comments"
              :key="index"
            >
              <b-media no-body>
                <b-media-aside class="mr-75">
                  <b-avatar
                    :src="comment.userImage"
                    size="38"
                  />
                </b-media-aside>
                <b-media-body>
                  <b-link class="text-body font-weight-bolder mb-25"> {{ comment.username }}</b-link>
                  <span class="text-muted ml-75 mr-50">|</span>
                  <small class="text-muted">{{ comment.date | dateFormat('MMMM DD, YYYY') }}</small>
                  <b-card-text>
                    {{ comment.commentText }}
                  </b-card-text>
                  <!-- <b-link>
                    <div class="d-inline-flex align-items-center">
                      <feather-icon
                        icon="CornerUpLeftIcon"
                        size="18"
                        class="mr-50"
                      />
                      <span>Reply</span>
                    </div>
                  </b-link> -->
                </b-media-body>
              </b-media>
            </b-card>
          </b-col>
          <!--/ blog comment -->

          <!-- Leave a Blog Comment -->
          <b-col
            cols="12"
            class="mt-2"
          >
            <h6 class="section-label">
              Leave a Comment
            </h6>
            <b-card>
              <validation-observer ref="formSubmitted">
                <!-- form -->
                <b-form  @submit.prevent="postComment">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group class="mb-2">
                        <validation-provider
                          #default="{ errors }"
                          name="comment"
                          rules="required|min:50"
                        >
                        <b-form-textarea
                          name="textarea"
                          rows="4"
                          placeholder="Statement or comment with at least 50 charaters"
                          v-model="commentDescription"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary float-right"
                        type="submit"
                      >
                        Post Comment
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card>
          </b-col>
          <!--/ Leave a Blog Comment -->
        </b-row>
        <!--/ blogs -->
      </div>
      <!--/ content -->
      </div>
    </template>
  </div>
</template>

<script>
import {
  BFormInput, BMedia, BAvatar, BMediaAside, BMediaBody, BImg, BLink, BFormGroup, BInputGroup, BInputGroupAppend,
  BCard, BRow, BCol, BBadge, BCardText, BDropdown, BDropdownItem, BForm, BFormTextarea, BFormCheckbox, BButton,
  BSpinner, BAlert, BFormInvalidFeedback
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { kFormatter } from '@core/utils/filter'
import { getFirestore, query, getDocs, getDoc, doc, collection, addDoc, orderBy, limit, serverTimestamp} from 'firebase/firestore'
import { getAuth } from "firebase/auth"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const db = getFirestore()
const auth = getAuth()

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BBadge,
    BCardText,
    BDropdown,
    BForm,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    BAlert,
    BSpinner,
    BFormInvalidFeedback,
    required,
    ToastificationContent
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      search_query: '',
      productDetail: null,
      comments: [],
      commentDescription: null,
      username: null,
      userData: null,
      blogSidebar: {},
      socialShareIcons: [
        'GithubIcon',
        'GitlabIcon',
        'FacebookIcon',
        'TwitterIcon',
        'LinkedinIcon',
      ],
    }
  },
  created() { 
    this.username = (auth && auth.currentUser)?auth.currentUser.displayName:null
    this.userData = JSON.parse(localStorage.getItem('userData'))

    if(router.currentRoute.params && router.currentRoute.params.name){
      this.productDetail = router.currentRoute.params
      this.getProductComments(router.currentRoute.params.id)
    }else{
      this.getProduct(router.currentRoute.params.id)
      this.getProductComments(router.currentRoute.params.id)
    }
  },
  methods: {
    kFormatter,
    tagsColor(tag) {
      if (tag === 'Quote') return 'light-info'
      if (tag === 'Gaming') return 'light-danger'
      if (tag === 'Fashion') return 'light-primary'
      if (tag === 'Video') return 'light-warning'
      if (tag === 'Food') return 'light-success'
      return 'light-primary'
    },
    async getProduct(id) {
      const productRes = await getDoc(doc(db, "products", id))
      if(!productRes.exists()) return
      this.productDetail = productRes.data()
      this.productDetail.date = this.productDetail.created_time.toDate()
    },
    async getProductComments(id) {
      const snapshot = await getDocs(query(collection(db, "products", id, "comments"), orderBy("commentedAt", "desc"), limit(50)))
      snapshot.forEach(doc => {
        let comment = doc.data()
        comment.date = doc.data().commentedAt.toDate()
        this.comments.push(comment)
      })
    },
    async postComment() {
      console.log('postComment')
      // TODO add a busy indicator
      // Validate form for to make sure data is correct
      const validateForm = await this.$refs.formSubmitted.validate()
      if (!validateForm) return

      // Create the product 
      const newComment = {commentText:this.commentDescription, username:auth.currentUser.displayName, userid:auth.currentUser.uid, userImage:this.userData.image, commentedAt: serverTimestamp() }
      await addDoc(collection(db, "products", router.currentRoute.params.id, "comments"), newComment)

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Comment Posted',
          icon: 'CoffeeIcon',
          variant: 'success',
        },
      })
      this.commentDescription = ''
      newComment.date = new Date()
      this.comments.unshift(newComment)
      this.productDetail.comments = parseInt(this.productDetail.comments)+1;
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
